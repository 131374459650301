

























































import { mixins } from "vue-class-component";
import ErrorMessageHandlerMixin from "@/misc/ErrorMessageHandler.mixins";
import { Component, Prop } from "vue-property-decorator";

@Component({})
export default class RegisterEasyComponent extends mixins(ErrorMessageHandlerMixin) {
  /**
   * Props from the parent component
   */
  @Prop({ default: "" })
  public name!: string;

  @Prop({ default: 1 })
  public cardCount!: number;

  @Prop({ default: "" })
  public description!: string;

  /**
   * Generator for max Item Count
   */
  public items: number[] = Array.from({
    length: 50
  }, (_, i) => (i + 1));

  /**
   * Local Values for the current View
   */
  public localName: string = "";
  private localCardCount: number = 1;
  public localComment: string = "";

  private created() {
    this.localName = this.name;
    this.localComment = this.description;
    this.localCardCount = this.cardCount;
  }

  /**
   * Check whether the name is entered or not
   * @private
   */
  private get nameEntered(): boolean {
    return this.localName.trim().length > 0;
  }

  /**
   * On Next Pressed
   * @private
   */
  private onNextStep() {
    // Names are missing show message and abort
    if (!this.nameEntered) {
      return this.$notifyErrorSimplified("GENERAL.NOTIFICATIONS.EASY_CARDS_USER_NAMES");
    }

    // Emit the event
    this.$emit("easyCard", this.localName, this.localComment, this.localCardCount);
  }
}
